import React from 'react'

import FormSuccessTemplate from '../../../components/templates/FormSuccessTemplate'

const Success = () => {
  return (
    <div>
      <FormSuccessTemplate
        title='Succès - Déposer une annonce'
        buttonLabel='Voir les annonces'
        redirection='/nos-annonces/'
        messageTitle='Votre annonce sera en ligne dans quelques minutes !'
        description='Votre annonce a bien été déposé sur notre plateforme TrouverMonLocalPro !'
        messageContent='Nous vous remercions de votre confiance. Si vous avez la moindre question ou besoin d&apos;assistance, n&apos;hésitez pas à nous contacter à: contact@trouvermonlocalpro.fr'
        messageSubContent='Vous allez recevoir un email de confirmation. Pensez à regarder vos spams.' />
    </div>
  )
}

export default Success
